/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import './style.scss';

const LoaderPulse = () => (
  <div id="loader-pulse">
    <div className="l-pulse">
      <div></div>
      <div></div>
    </div>
  </div>
);

export default LoaderPulse;
