import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable prettier/prettier */
/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  seller: '',
  product: null,
  addressId: null,
};

export const instantCheckoutSlice = createSlice({
  name: 'instantCheckout',
  initialState,
  reducers: {
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setAddressId: (state, action) => {
      state.addressId = action.payload;
    },
  },
});

export const {
  setSeller,
  setProduct,
  setAddressId
} = instantCheckoutSlice.actions;
export default instantCheckoutSlice.reducer;
