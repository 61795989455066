import { createSlice } from '@reduxjs/toolkit';

/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  browserId: '',
  lastTransaction: '',
  affiliatePhone: '',
  openProduct: {},
};

export const browserSlice = createSlice({
  name: 'browser',
  initialState,
  reducers: {
    setBrowserId: (state, action) => {
      state.browserId = action.payload;
    },
    resetBrowserId: (state) => {
      state.browserId = '';
    },
    setLastTransaction: (state, action) => {
      state.lastTransaction = action.payload;
    },
    resetLastTransaction: (state) => {
      state.lastTransaction = '';
    },
    setAffiliatePhone: (state, action) => {
      state.affiliatePhone = action.payload;
    },
    resetAffiliatePhone: (state) => {
      state.affiliatePhone = '';
    },
    setOpenProduct: (state, action) => {
      state.openProduct = action.payload;
    },
    resetOpenProduct: (state) => {
      state.openProduct = {};
    },
  },
});

export const {
  setBrowserId,
  resetBrowserId,
  setLastTransaction,
  resetLastTransaction,
  setAffiliatePhone,
  resetAffiliatePhone,
  setOpenProduct,
  resetOpenProduct,
} = browserSlice.actions;

export default browserSlice.reducer;
