/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductRedux: (state, action) => {
      state.data = action.payload;
    },
    resetProductRedux: (state) => {
      state.data = [];
    },
  },
});

export const { setProductRedux, resetProductRedux } = productSlice.actions;
export default productSlice.reducer;
