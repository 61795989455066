/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderTriangle from '../components/organisms/LoaderTriangle';
import LoaderPulse from '../components/organisms/LoaderPulse';

const PreviewCheckout = lazy(() => import('../pages/InstantCheckout/PreviewCheckout'));
const InstantCheckout = lazy(() => import('../pages/InstantCheckout'));
const AddAddressPage = lazy(() => import('../pages/Address/Add'));
const LandingPage = lazy(() => import('../pages/LandingPage'));
const LandingPage2 = lazy(() => import('../pages/LandingPage2'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const MainPage = lazy(() => import('../pages/MainPage'));
const Cart = lazy(() => import('../pages/v2/Cart'));
const Checkout = lazy(() => import('../pages/v3/Checkout'));
const Categories = lazy(() => import('../pages/Categories'));
const Order = lazy(() => import('../pages/Order'));
const Payment = lazy(() => import('../pages/Payment'));
const ProductListOnCategory = lazy(() =>
  import('../pages/ProductListOnCategory'),
);
const Search = lazy(() => import('../pages/Search'));
const ProductDetail = lazy(() => import('../pages/ProductDetail'));
const ProductReview = lazy(() => import('../pages/ProductReview'));
const Chat = lazy(() => import('../pages/Chat'));
const Transaction = lazy(() => import('../pages/Transaction'));
const TransactionDetail = lazy(() => import('../pages/TransactionDetail'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const SuccessPage = lazy(() => import('../pages/Success'));
const CustomerCare = lazy(() => import('../pages/CustomerCare'));
const PaymentIds = lazy(() => import('../pages/PaymentIds'));

const Router = () => {
  const exact = true;
  return (
    <>
      <Suspense fallback={<LoaderPulse />}>
        <Routes>
          <Route exact={exact} path="*" element={<ErrorPage />} />
          <Route
            exact={exact}
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            exact={exact}
            path="/customer-care"
            element={<CustomerCare />}
          />
          <Route exact={exact} path="/error" element={<ErrorPage />} />
          <Route exact={exact} path="/landingpage" element={<LandingPage />} />
          <Route exact={exact} path="/" element={<LandingPage2 />} />
          <Route exact={exact} path="/home" element={<LandingPage2 />} />
          <Route
            exact={exact}
            path="/sukses-verifikasi"
            element={<SuccessPage />}
          />
          <Route exact={exact} path="/:affiliateId" element={<MainPage />} />
          <Route exact={exact} path="/:affiliateId/order" element={<Order />}>
            <Route exact={exact} path="*" element={<ErrorPage />} />
          </Route>
          <Route exact={exact} path="/:affiliateId/cart" element={<Cart />}>
            <Route exact={exact} path="*" element={<ErrorPage />} />
          </Route>
          <Route
            exact={exact}
            path="/:affiliateId/instant-checkout" element={<InstantCheckout />}>

            <Route exact={exact} path="*" element={<ErrorPage />} />
          </Route>
          {/* <Route exact={exact} path="/:affiliateId/instant-checkout/preview" element={<PreviewCheckout />} /> */}
          <Route
            exact={exact}
            path="/:affiliateId/checkout"
            element={<Checkout />}>
            <Route exact={exact} path="*" element={<ErrorPage />} />
          </Route>
          <Route exact={exact} path="/:affiliateId/product">
            <Route exact={exact} path=":productId" element={<ProductDetail />}>
              <Route exact={exact} path="reviews" element={<ProductReview />} />
              <Route exact={exact} path="*" element={<ErrorPage />} />
            </Route>
            <Route exact={exact} path="*" element={<ErrorPage />} />
          </Route>
          <Route
            exact={exact}
            path="/:affiliateId/category"
            element={<Categories />}>
            <Route
              exact={exact}
              path=":categoryId"
              element={<ProductListOnCategory />}
            />
            <Route exact={exact} path="*" element={<ErrorPage />} />
          </Route>
          <Route
            exact={exact}
            path="/:affiliateId/search"
            element={<Search />}
          />
          <Route
            exact={exact}
            path="/:affiliateId/categories/*"
            element={<ErrorPage />}
          />
          <Route exact={exact} path="/payment" element={<Payment />} />
          <Route exact={exact} path="/payment/:code" element={<PaymentIds />} />
          <Route exact={exact} path="/payment/*" element={<ErrorPage />} />
          <Route exact={exact} path="/:affiliateId/chat" element={<Chat />} />
          <Route
            exact={exact}
            path="/:affiliateId/transaction"
            element={<Transaction />}
          />
          <Route
            exact={exact}
            path="/:affiliateId/transaction/detail"
            element={<TransactionDetail />}
          />
          <Route exact={exact} path="/:affiliateId/*" element={<ErrorPage />} />
        </Routes>
      </Suspense >
      <ToastContainer />
    </>
  );
};

export default Router;
