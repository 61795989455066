import { createSlice } from '@reduxjs/toolkit';

/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  data: [],
  counter: {
    totalProducts: 0,
    totalProductsQuantity: 0,
    needUpdate: false,
  },
};
export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartRedux: (state, action) => {
      state.data = action.payload;
    },
    resetCartRedux: (state) => {
      state.data = [];
    },
    setCartCounter: (state, action) => {
      state.counter = action.payload;
    },
    resetCartCounter: (state) => {
      state.counter = {
        totalProducts: 0,
        totalProductsQuantity: 0,
        needUpdate: false,
      };
    },
    setNeedUpdate: (state, action) => {
      state.counter.needUpdate = action.payload;
    },
  },
});

export const {
  setCartRedux,
  resetCartRedux,
  setCartCounter,
  resetCartCounter,
  setNeedUpdate,
} = cartSlice.actions;
export default cartSlice.reducer;
