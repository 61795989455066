import { createSlice } from '@reduxjs/toolkit';

/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  address: [],
};

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses: (state, action) => {
      state.address = action.payload;
    },
    resetAddresses: (state) => {
      state.address = [];
    },
  },
});

export const { setAddresses, resetAddresses } = addressesSlice.actions;
export default addressesSlice.reducer;
